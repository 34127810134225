import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String,
    open: { type: Boolean, default: false }
  }

  connect () {
    if (this.openValue) {
      this.toggleModal()
    }
  }

  toggleModal () {
    document.querySelector('#' + this.idValue + ' .modal').classList.toggle('hidden')
    document.querySelector('#' + this.idValue + ' .modal-overlay').classList.toggle('open')
    document.body.classList.toggle('overflow-hidden')
  }
}
