import { Controller } from '@hotwired/stimulus'
import { autofill } from '@mapbox/search-js-web'

export default class extends Controller {
  static targets = ['city', 'country', 'line', 'postalCode']

  initialize () {
    this.mapboxAutofill = autofill({
      accessToken: process.env.MAPBOX_TOKEN,
      options: { language: 'fr' }
    })

    this.mapboxAutofill.addEventListener('retrieve', (event) => this.updateSuggestion(event))
  }

  updateSuggestion (e) {
    const address = e.detail.features[0].properties

    this.cityTarget.value = address.address_level2 || ''
    this.countryTarget.value = address.country_code.toUpperCase() || ''
    this.lineTarget.value = address.address_line1 || ''
    this.postalCodeTarget.value = address.postcode || ''
  }
}
