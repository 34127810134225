import { Controller } from '@hotwired/stimulus'
import Mailcheck from 'mailcheck'

export default class EmailController extends Controller {
  static targets = ['input', 'error']

  initialize () {
    this.containerTarget = this.element
  }

  resetCheck () {
    this.containerTarget.classList.remove('field_with_errors')

    if (this.hasErrorTarget) this.errorTarget.remove()
  }

  displayError (suggestion) {
    const message = `
      <div class="mt-2 text-red-100 font-downcase font-medium text-sm" data-email-target="error">
        Veux-tu dire <a class="text-black-100 cursor-pointer" data-action="click->email#replaceEmail">${suggestion.full}</a> ?
      </div>
    `

    this.containerTarget.classList.add('field_with_errors')
    this.containerTarget.insertAdjacentHTML('beforeend', message)
  }

  replaceEmail (event) {
    this.inputTarget.value = event.currentTarget.text
    this.checkEmail()
  }

  checkEmail () {
    this.resetCheck()
    const currentInputValue = this.inputTarget.value
    Mailcheck.run({
      email: currentInputValue,
      suggested: suggestion => {
        this.displayError(suggestion)
      }
    })
  }
}
