import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    event: String,
    properties: Object,
    options: Object
  }

  track () {
    const event = this.eventValue
    const properties = this.propertiesValue
    const options = this.optionsValue

    window.analytics.track(event, properties, options)
  }
}
