import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    toastAppearanceDuration: { type: Number, default: 3000 }
  }

  copy (event) {
    const copyText = event.params.copyText
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      navigator.clipboard.writeText(copyText).then(() => {
        this.dispatchCopyEvent()
      })
    } else {
      // text area method
      const textArea = document.createElement('textarea')
      textArea.value = copyText
      // make the textarea out of viewport
      textArea.style.position = 'fixed'
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      new Promise((resolve, reject) => {
        // here the magic happens
        document.execCommand('copy') ? resolve() : reject(new Error('Voucher code couldn’t be copied'))
        textArea.remove()
      }).then(() => {
        this.dispatchCopyEvent()
      })
    }
  }

  dispatchCopyEvent () {
    const evt = new Event('displayToast')
    document.dispatchEvent(evt)
  }
}
