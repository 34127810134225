import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    message: String
  }

  sendIntercomMessage (event) {
    if (window.Intercom) {
      window.Intercom('showNewMessage', this.messageValue)
      event.preventDefault()
    }
  }
}
