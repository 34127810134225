import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['showIcon', 'hideIcon', 'input']

  show () {
    this.inputTarget.type = 'text'
    this.showIconTarget.classList.add('hidden')
    this.hideIconTarget.classList.remove('hidden')
  }

  hide () {
    this.inputTarget.type = 'password'
    this.showIconTarget.classList.remove('hidden')
    this.hideIconTarget.classList.add('hidden')
  }
}
