import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['escButton', 'input', 'option']
  static classes = ['selected']

  toggle () {
    this.element.classList.toggle('hidden')

    if (this.element.classList.contains('hidden')) {
      this.inputTarget.value = ''
      this.inputTarget.blur()
      this.element.dataset.state = 'closed'
      document.querySelector('body').classList.remove('overflow-hidden')
      if (this.currentlySelectedOption === undefined) {
        this.selectOption(this.optionTargets[0])
      }
    } else {
      this.inputTarget.focus()
      document.querySelector('body').classList.add('overflow-hidden')
      this.element.dataset.state = 'open'
    }
  }

  close (event) {
    this.element.classList.add('hidden')
    this.element.dataset.state = 'closed'
    this.inputTarget.value = ''
    this.inputTarget.blur()
  }

  search (event) {
    this.optionTargets.forEach(option => {
      if (option.textContent.toLowerCase().includes(event.target.value.toLowerCase())) {
        option.classList.remove('hidden')
      } else {
        option.classList.add('hidden')
      }
    })
  }

  navigateUp (event) {
    const currentIndex = this.optionTargets.indexOf(this.currentlySelectedOption)
    const previousIndex = currentIndex === 0 ? this.optionTargets.length - 1 : currentIndex - 1
    this.selectOption(this.currentlySelectedOption, this.optionTargets[previousIndex])
  }

  navigateDown (event) {
    const currentIndex = this.optionTargets.indexOf(this.currentlySelectedOption)
    const nextIndex = currentIndex === this.optionTargets.length - 1 ? 0 : currentIndex + 1
    this.selectOption(this.currentlySelectedOption, this.optionTargets[nextIndex])
  }

  select (event) {
    this.selectOption(this.currentlySelectedOption, event.target)
  }

  selectOption (currentOption, nextOption) {
    currentOption.dataset.selected = 'false'
    currentOption.classList.remove(this.selectedClass)

    nextOption.scrollIntoView({ block: 'nearest' })
    nextOption.classList.add(this.selectedClass)
    nextOption.dataset.selected = 'true'
  }

  visitSelectedOption (event) {
    this.currentlySelectedOption.click()
  }

  get currentlySelectedOption () {
    return this.optionTargets.find(option => option.dataset.selected === 'true')
  }
}
