import { Controller } from '@hotwired/stimulus'
import { throttle } from 'lodash'

export default class extends Controller {
  static targets = ['banner', 'body', 'menu', 'nav']
  static values = {
    previousScrollPosition: {
      type: Number,
      default: 0
    },
    scrollDown: {
      type: Boolean,
      default: false
    }
  }

  connect () {
    this.preload()
    this.hideOnScroll = throttle(this.hideOnScroll.bind(this), 100)
  }

  preload () {
    setTimeout(() => {
      this.bodyTarget.classList.remove('preload')
    }, 1000)
  }

  toggleMenu () {
    this.bodyTarget.classList.toggle('overflow-hidden')
  }

  closeMenu () {
    this.menuTarget.removeAttribute('open')
    this.bodyTarget.classList.remove('overflow-hidden')
  }

  hideOnScroll () {
    if (this.isScrollingDown()) {
      if (this.previousScrollPositionValue > this.bannerheight()) {
        this.navTarget.style = `transform: translateY(-${this.navTarget.offsetHeight}px);`
      }
    } else {
      this.navTarget.style = 'transform: translateY(0);'
    }
  }

  bannerheight () {
    const space = this.navTarget.offsetHeight * 0.5 // set space for a better UX
    return this.hasBannerTarget ? (this.bannerTarget.offsetHeight + space) : space
  }

  isScrollingDown () {
    const scrollPosition = window.scrollY
    this.scrollDownValue = scrollPosition > this.previousScrollPositionValue
    this.previousScrollPositionValue = scrollPosition

    return this.scrollDownValue
  }
}
